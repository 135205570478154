import React from "react";

const BannerWidget = () => {
  return (
    <div className="banner-widget" id="banner-widget-js">
      {/* Code is appended inside here */}
    </div>
  );
};

export default BannerWidget;
